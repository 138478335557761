require('./bootstrap');

//$(document).ready(function() {



  let catalog = $('.catalog');
  let catalogCategories = $('.catalog .catalog__categories');

  catalogCategories.find('.catalog__category-item button').click(function() {
    let self = $(this);
    let service = self.data('service');

    if (!service) {
      return;
    }

    //Обновление меню
    catalogCategories.find('.catalog__category-item').removeClass('catalog__category-item--active');
    self.closest('.catalog__category-item').addClass('catalog__category-item--active');

    //Обновление контента
    catalog
        .find('.catalog__masters-group')
        .addClass('d-none')
        .each(function(element) {
          let self = $(element);
          if (self.data('service') === service) {
            self.removeClass('d-none')
          }
    });
  });

  let userType = $('.user-type');
  let isMasterField =  $('input[name="is_master"]');
  userType.click(function () {
    let self = $(this);
    isMasterField.prop('checked', Boolean(self.data('value')));
    userType.removeClass('active');
    self.addClass('active');
  });

  let collapseButton =  $('button[data-collapse]');

  collapseButton.click(function () {
    let self = $(this);
    const id = self.data('collapse');
    const target = $(id);
    if (target.hasClass('open')) {
      target.removeClass('open');
    } else {
      target.addClass('open');
    }
  });

  let hash = window.location.hash || '';

  if (hash.startsWith('#img')) {
    window.history.replaceState(null, null, ' ');
    $(hash).click();
  }

  if (Choices) {
    const serviceFiled = document.getElementById('service-filed');

    if (serviceFiled) {
      const maxItemCount = serviceFiled.dataset.maxItemCount || -1;
      const choices = new Choices(serviceFiled, {
        searchResultLimit: 20,
        removeItemButton: true,
        maxItemCount,
        loadingText: 'Загрузка...',
        noResultsText: 'Нет вариантов для выбора',
        itemSelectText: 'Нажмите, чтобы выбрать',
        noChoicesText: 'Нет вариантов для выбора',
        maxItemText: (maxItemCount) => {
          return `Максимальное количество вариантов для выбора: ${maxItemCount}`;
        },
        classNames: {
          inputCloned: 'choices__input--cloned form-control',
        }
      });
      choices.removeActiveItemsByValue(''); //remove selected placeholder
    }
  }

  $('.action--logout').click(function(event) {
    event.preventDefault();
    document.getElementById('logout-form').submit();
  })

//});
